// src/components/language-selection/LanguageSelection.tsx
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DefaultButton } from '@fluentui/react';
import { Box, Flex, Image, Provider, teamsTheme, Text } from '@fluentui/react-northstar';
import LanguageDropDown from '../../ui-components/LanguageDropDown';
import { useNavigate } from 'react-router-dom';
import useFetchUserAttributes from '../../utils/useFetchUserAttributes';

const LanguageSelection: React.FC = () => {
  const navigate = useNavigate();
  const intlInstance = useIntl();
  const [fullname, setFullname] = useState('');
  const [disableNext, setDisableNext] = useState(true);


  const userAttributes: any = useFetchUserAttributes();

  useEffect(() => {
    
    const fullNameString = `Welcome ${userAttributes.givenName}`;
    
    setFullname(fullNameString);
  }, [userAttributes]);

  const handleLanguage = (selectedValue: any) => {
    
    if (selectedValue.hasOwnProperty('key')) {
      localStorage.setItem('lang', selectedValue['key']);
      
      setDisableNext(false);
    }
  }

  const handleLanguageSelection = () => {
    navigate('/chat');
  };

  return (
    <Provider
      className='language-provider'
      theme={teamsTheme}>

      <Flex gap="gap.large" hAlign='start' className='login-container'>
        <Box className='login-left-align'>
          <Flex column gap="gap.large" hAlign='start' vAlign="center" className='left-image-container' >
            <Image src='./images/onboarding.svg' fluid className='left-image' />
            <Flex.Item align="start">
              <Flex column hAlign='start' className='left-bottom-text'>
                <Text className='left-intro' content={intlInstance.formatMessage({ id: 'leftIntro1' })} />
                <Text className='left-intro-second' content={intlInstance.formatMessage({ id: 'leftIntro2' })} />
                <div className='login-footer-text'>{intlInstance.formatMessage({ id: 'footerText1' })} </div>
              </Flex>
            </Flex.Item>
          </Flex>
        </Box>
        <Box className='login-right-form'>
          <Flex column gap="gap.large" vAlign="center" className='login-right-flex'>
            <Flex.Item align="center">
              <Flex column className='login-right-content'>
                <Flex.Item align="start" >
                  <Flex vAlign='center'>
                    <Image src='./images/emoji.svg' />
                    <Flex.Item align="start" >
                      <Text content={fullname} className='welcome-text' />
                    </Flex.Item>
                  </Flex>
                </Flex.Item>
                <Flex.Item align="start" >
                  <Text content={intlInstance.formatMessage({ id: 'selectPreferredText' })} className='padding-text preferred-language' />
                </Flex.Item>
                <Flex.Item align="start" >
                  <LanguageDropDown setLanguageValue={handleLanguage} ></LanguageDropDown>
                </Flex.Item>
                <Flex.Item align="start" >
                  <DefaultButton disabled={disableNext} primary onClick={handleLanguageSelection} className="next-button">
                    {intlInstance.formatMessage({ id: 'next' })}
                  </DefaultButton>
                </Flex.Item>
              </Flex>
            </Flex.Item>
          </Flex>
        </Box>
      </Flex>
    </Provider>
  );
};

export default LanguageSelection;
