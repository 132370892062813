import React, { useEffect } from 'react'
import { Dropdown } from '@fluentui/react-northstar'

const inputItems = [
  {
    key: 'en_US',
    header: 'English'
  },
  // {
  //   key: 'pt_PT',
  //   header: 'Portuguese',
  // },
  // {
  //   key: 'fr_FR',
  //   header: 'French',
  // },
  // {
  //   key: 'zh-Hant', // Traditional chinese
  //   header: 'Mandarin'
  // },
  // {
  //   key: 'de',
  //   header: 'German'
  // },
  // {
  //   key: 'ko',
  //   header: 'Korean'
  // },
]

interface LanguageSelectionProps {
  setLanguageValue: (data: any) => void;
}
const LanguageDropDown: React.FC<LanguageSelectionProps> = ({setLanguageValue}) => {

  const [selectedValue, setSelectedValue] = React.useState({} as any);

  useEffect(() => {
    setLanguageValue(selectedValue);
  }, [selectedValue]);
  setLanguageValue(inputItems[0]);
  return (
  <Dropdown
    className='language-dropdown'
    items={inputItems}
    placeholder="Select your Language"
    checkable
    defaultValue={inputItems[0]}
    getA11ySelectionMessage={{
      onAdd: (item) => {
        setSelectedValue(item);
        return `${item} has been selected.`
      },
    }}
  />
)} 
export default LanguageDropDown