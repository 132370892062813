import {
    Flex,
    Box,
    Image,
    List,
    Text,
    Tooltip
} from '@fluentui/react-northstar';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Stack } from '@fluentui/react';
import useFetchUserAttributes from '../utils/useFetchUserAttributes';
import { useIntl } from 'react-intl';

interface QuestionProps {
    setQuestionValue: (data: any) => void;
}

const LeftMenu: React.FC<QuestionProps> = ({ setQuestionValue }) => {
    const intlInstance = useIntl();
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const location = useLocation();
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [initials, setInitials] = useState('');
    const [isChatHovered, setIsChatHovered] = useState(false);
    const [isLogoutHovered, setIsLogoutHovered] = useState(false);
    const [menuVisiblity, setMenuVisiblity] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState({} as any);

    useEffect(() => {
        setQuestionValue(selectedValue);
    }, [selectedValue]);

    const userAttributes: any = useFetchUserAttributes();


    useEffect(() => {
        setMenuListActive(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        // TODO: change it to redux
        setNameValue('name', userAttributes.fullName);
        setNameValue('email', userAttributes.email);
    }, []);

    const toggleMenu = () => {
        setMenuVisiblity(!menuVisiblity);
    }

    const setNameValue = (nameKey: string, nameValue: string) => {
        switch (nameKey) {
            case 'name':
                const fullNameString = `${nameValue}`;
                // Extract initials
                const initials = fullNameString
                    .split(', ')
                    .map(name => name.charAt(0))
                    .reverse() // Reverse the order of initials
                    .join('');
                setInitials(initials);
                setFullname(fullNameString);
                break;

            case 'email':
                const emailString = `${nameValue}`;
                setEmail(emailString);
                break;

            default:
                break;
        }
    }

    const setMenuListActive = (pathName: any) => {
        if (pathName === '/chat') {
            setSelectedIndex(0);
            setIsChatHovered(true);
        } else if (pathName === '/settings') {
            setSelectedIndex(1);
        }

    }

    const setQuestion = (event: any) => {
        const question = event.currentTarget.dataset.content;
        setSelectedValue(question);
        
    }


    const itemsMenuList = [
        {
            key: 'chatWindow',
            media: (
                <Image
                    src={isChatHovered ? './images/fi-message-square-hover.svg' : './images/fi-message-square.svg'}
                    avatar
                    className='menu-icon chat-image'
                />
            ),
            content: <Stack
                onMouseEnter={() => setIsChatHovered(true)}
                onMouseLeave={() => {
                    if (selectedIndex !== 0) {
                        setIsChatHovered(false);
                    }
                }}
            >
                <Link to={{ pathname: '/chat' }}>Chat Window</Link>
            </Stack>,
        },
    ]

    const itemsMenuBottomList = [
        {
            key: 'profileInfo',
            className: 'profile-info',
            media: (
                <Text
                    content={initials} // Text to be displayed in the avatar
                    className='initials-text'
                />
            ),
            header: fullname,
            content: <Tooltip
                pointing
                trigger={<Text content={email} />}
                content={email}
            />,
        },
        {
            key: 'logout',
            media: (
                <Image
                    src={isLogoutHovered ? './images/fi-log-out-hover.svg' : './images/fi-log-out.svg'}
                    avatar
                    className='menu-icon'
                />
            ),
            content: (
                <Stack
                    onMouseEnter={() => setIsLogoutHovered(true)}
                    onMouseLeave={() => {
                        setIsLogoutHovered(false);
                    }}
                >
                    <Link to={{ pathname: '/logout' }}>Logout</Link>
                </Stack>
            ),
        },
        {
            key: 'version',
            content: 'v1.0',
        }
    ]

    return (
        <><Box className='top-header'>
            <Flex fill gap="gap.small" padding="padding.medium" space='between' vAlign='center'>
                <Flex className='top-logo' gap="gap.small" padding="padding.medium" hAlign='start' vAlign='center'>
                    <Image
                        src="./images/fluke_logo.png"
                        avatar
                        className='profile-top-icon' />
                    <Flex.Item align="center">
                        <Text className='app-title' content={intlInstance.formatMessage({ id: 'appTitle' })} />
                    </Flex.Item>
                </Flex>
                <Flex gap="gap.small" padding="padding.medium" hAlign='end' vAlign='center'>
                    <Image
                        className='cursor-pointer'
                        src="./images/ham-menu.svg"
                        onClick={toggleMenu}
                    />
                </Flex>
            </Flex>
        </Box>
            <Box className={menuVisiblity ? 'left-menu show-menu' : 'left-menu'} >
                <Flex hAlign='end' vAlign='center'>
                    <Image
                        className='cursor-pointer'
                        src="./images/menu-close.svg"
                        onClick={toggleMenu}
                    />
                </Flex>
                <Box className='left-menu-list-wrapper'>
                    <List
                        className='left-menu-menu-list'
                        items={itemsMenuList}
                        selectable
                        selectedIndex={selectedIndex}
                    />
                </Box>
                <Box className='left-menu-bottom'>
                    <List
                        className='left-menu-menu-list'
                        items={itemsMenuBottomList}
                        selectable
                    />
                </Box>
            </Box></>
    )
};

export default LeftMenu;