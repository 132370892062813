// validateTokenExpirationCheck.ts
import { useEffect } from 'react';
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useNavigate, useLocation } from 'react-router-dom';
import { FTVJwtPayload, getToken } from './useFetchUserAttributes';

const useValidateTokenExpirationCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        // Check if the user is authenticated
        // const isAuthenticated = instance.getAllAccounts().length > 0;
        let { id_token } = getToken()
        const pathName = location.pathname;
        const tokenValidity = isTokenExpired(id_token);

        if (!id_token && tokenValidity) {
          // Redirect to login if not authenticated
          localStorage.removeItem('ftvToken');
          navigate('/home');
          return;
        } else if (!tokenValidity && (pathName === "/" || pathName === "/home" || pathName === "/login" )) {
          // Redirect to login if not authenticated
          navigate('/chat');
          return;
        }

        

        

        // Check token expiration
        // TODO: Need to update code for 
        // const accessTokenResponse = await instance.acquireTokenSilent({
        //   scopes: ["openid", "profile"],
        // });

        // if (!accessTokenResponse || !accessTokenResponse.accessToken) {
        //   // Clear any cached tokens
        //   instance.clearCache();
          
        //   // Redirect to login screen
        //   navigate('/login');
        // }
      } catch (error) {
        console.error('Error checking token expiration:', error);
      }
    };

    const isTokenExpired = (token: any) => {
      const payload = jwtDecode<FTVJwtPayload>(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return payload['exp'] < currentTime;
    };

    checkTokenExpiration();
  }, [navigate]);

  return {};
};

export default useValidateTokenExpirationCheck;
