// useFetchUserAttributes.ts
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
const useFetchUserAttributes = () => {
    const navigate = useNavigate();
  
    let { id_token } = getToken();
    let givenName = '', email = '', familyName = '', fullName = '';
    if (!id_token) {
      console.log('inside id token');
      // Redirect to login if not authenticated
      
      return {
        givenName,
        email,
        familyName,
        fullName
      };
    }
    const userAttributes = jwtDecode(id_token) as FTVJwtPayload;
    givenName = userAttributes.given_name;
    email = userAttributes.email;
    familyName = userAttributes.family_name
    fullName = userAttributes.name
    return {
      givenName,
      email,
      familyName,
      fullName
    };
  

};

export interface FTVJwtPayload extends JwtPayload {
  given_name: string,
  email: string,
  family_name: string,
  name: string,
  exp: number; // Add other properties if needed
}

export const getToken = () => {
  const token = localStorage.getItem('ftvToken') as string;
  if (!!token) {
    let { id_token, access_token } = JSON.parse(token);
    return { id_token, access_token };
  }
  return {
    id_token: '',
    access_token: ''
  };
}

export default useFetchUserAttributes;
