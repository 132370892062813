// src/services/awsService.ts
import * as AWS from 'aws-sdk';
import { Lambda } from 'aws-sdk';
import { getToken } from "../utils/useFetchUserAttributes";

const sendMessageFunction = process.env.REACT_APP_SERVICE_LAMBDA as string;
const reactionFunction = process.env.REACT_APP_REACTION_LAMBDA as string;
const awsRegion = process.env.REACT_APP_AWS_REGION as string;
const awsCognitoIdentityPoolId = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string;
const userPoolAwsLogin =  process.env.REACT_APP_AWS_UserPoolAwsLogin as string;

export const generateGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // tslint:disable-next-line:no-bitwise
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    // tslint:disable-next-line: no-magic-numbers
    return v.toString(16);
  });
}

export const sendMessageToLambda = (content: string, interactionId: string, selectedDataSource: string[], token: string, sharkSessionId: string, callback: any) => {
  const languageLocale = localStorage.getItem('lang');
  let { id_token } = getToken();

  
  let requestBody = {
      "context": {
          "lpEvent": {
              "event": {
                  "message": content
              },
              "conversationContext": {
                  "visitor": {
                      "sharkSessionId": sharkSessionId
                  }
              }
          },
          "selectedLanguage": languageLocale,
          "tokenId": id_token,
          "dataSource": ['confluence','jira','github','sharepoint', 'webpages'],
          "interaction_id": interactionId
      }
  }
  if (selectedDataSource.length > 0) {
    requestBody['context']['dataSource'] = selectedDataSource;
  }
  try {

    let userPoolConfig: string = userPoolAwsLogin;
    let loginObj: any = {};
    loginObj[userPoolConfig] = id_token;
    let awsCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsCognitoIdentityPoolId,
        Logins: loginObj
    });
    AWS.config.credentials = awsCredentials;
    AWS.config.region = awsRegion;
    const lambda = new Lambda({
      region: 'us-east-1'
    });
    
    const params = {
        FunctionName : sendMessageFunction,
        InvocationType : 'RequestResponse',
        LogType : 'None',
        Payload : JSON.stringify({"body": JSON.stringify(requestBody)})
    }
    lambda.invoke(params, (err, data) => {
        if (err) {
            console.error('Error calling lambda ', err);
            const errorResponse = {
                "messages": [
                    "Unable to get any response."
                ]
              }
            callback(JSON.stringify(errorResponse));
        } else {
            const responsePayLoad= data.Payload;
            const responseString = JSON.stringify(responsePayLoad);

            const convert = JSON.parse(responseString, (key, value) => {
                if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) {
                    return JSON.parse(value);
                }
                return value;
            });
            callback(convert.body);
        }
    });
  } catch(error) {
    console.error(error);
    const errorResponse = {
      "messages": [
          "Unable to get any response."
      ]
    }
    callback(JSON.stringify(errorResponse));
  }
};

export const thumbsUpReactionLambda = (requestParam: any, callback: any) => {
  const languageLocale = localStorage.getItem('lang');
  let { id_token } = getToken();

  let requestBody = requestParam
  requestBody = {requestBody, ...{"tokenId": id_token}}
  try {

    let userPoolConfig: string = userPoolAwsLogin;
    let loginObj: any = {};
    loginObj[userPoolConfig] = id_token;
    let awsCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsCognitoIdentityPoolId,
        Logins: loginObj
    });
    AWS.config.credentials = awsCredentials;
    AWS.config.region = awsRegion;
    const lambda = new Lambda({
      region: 'us-east-1'
    });
    
    const params = {
        FunctionName : reactionFunction,
        InvocationType : 'RequestResponse',
        LogType : 'None',
        Payload : JSON.stringify({"body": JSON.stringify(requestBody)})
    }
    lambda.invoke(params, (err, data) => {
        if (err) {
            console.error('Error calling lambda ', err);
            const errorResponse = {
                "errorMsg": "Unable to get any response."
              }
            callback(JSON.stringify(errorResponse));
        } else {
            const responsePayLoad= data.Payload;
            const responseString = JSON.stringify(responsePayLoad);

            const convert = JSON.parse(responseString, (key, value) => {
                if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) {
                    return JSON.parse(value);
                }
                return value;
            });
            callback(convert.body);
        }
    });
  } catch(error) {
    console.error(error);
    const errorResponse = {
      "errorMsg": "Unable to get any response."
    }
    callback(JSON.stringify(errorResponse));
  }
};

export const fetchPromptsParam = (callback: any) => {
  let { id_token } = getToken();

  try {

    let userPoolConfig: string = userPoolAwsLogin;
    let loginObj: any = {};
    loginObj[userPoolConfig] = id_token;
    let awsCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsCognitoIdentityPoolId,
        Logins: loginObj
    });
    AWS.config.region = awsRegion;

    AWS.config.update({
      region: "us-east-1",
    })
    const fetchParam = new AWS.SSM({ region: "us-east-1" });
    
    const params = {
        Name: '/superapp/example-prompts'
    }

    fetchParam.getParameter(params, (err, data) => {
      if (err) {
          console.error('Error calling SSM param ', err);
          const errorResponse = {
              "messages": [
                  "Unable to fetch param."
              ]
            }
          callback(JSON.stringify(errorResponse));
      } else {
          const responsePayLoad= data?.Parameter;
          const response = responsePayLoad?.Value;
          
          callback(response);
      }
    });
  } catch(error) {
    console.error(error);
    const errorResponse = {
      "messages": [
          "Unable to fetch param."
      ]
    }
    callback(JSON.stringify(errorResponse));
  }
};

export const fetchPromptsParamFromConfig = async (callback: any) => {
  try {
    const response = await fetch('/PromptConfig.json');
    if (!response.ok) {
      
      throw new Error('Failed to fetch configuration');
    }

    const data = await response.json();
    callback(data);
  } catch (error) {
    console.error()
  }
};
