import React from 'react';
import {
    Flex,
    Provider,
    teamsTheme,
    teamsDarkTheme,
    Text
} from '@fluentui/react-northstar';
// import { AuthenticatedTemplate } from '@azure/msal-react';
import LeftMenu from '../../ui-components/LeftMenu';
import LanguageDropDown from '../../ui-components/LanguageDropDown';
import useValidateTokenExpirationCheck from '../../utils/useValidateTokenExpiration';

const Settings: React.FC = () => {
    useValidateTokenExpirationCheck();

    const handleLanguage = (selectedValue: any) => {
        
    }

    return (
        <>
                <Flex column>
                <Provider theme={teamsDarkTheme}>
                    <LeftMenu setQuestionValue={() =>{}}></LeftMenu>
                </Provider>
                <div className='right-topcontainer'>
                    <Provider
                        className='lang-provider'
                        theme={teamsTheme}>
                        <Flex column className='settings-right-content' padding='padding.medium'>
                            <Flex.Item align="start" >
                                <Text content="Select language" className='padding-text' />
                            </Flex.Item>
                            <Flex.Item align="start" >
                                <LanguageDropDown setLanguageValue={handleLanguage}></LanguageDropDown>
                            </Flex.Item>
                        </Flex>

                    </Provider>
                </div>
                </Flex>
        </>
    );
};

export default Settings;
